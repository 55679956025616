import * as React from "react"
import { Link } from "gatsby"
import { useState } from "react"
import { PlayOutline, StarOutline, InformationCircleOutline } from 'react-ionicons'
import Helmet from 'react-helmet'
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import EndCheckout from '../../components/EndCheckout.js'
import Footer from '../../components/Footer.js'
import Logo from '../../components/Logo.js'
import { CheckoutContext } from "../../context/CheckoutContext"
import ReactPlayer from 'react-player'



const Stem = (props) => {
    return <article class="box">

        <div className="columns is-mobile content">
            <div className="column is-narrow">
                <p className="image is-96x96 stem-image">
                    <img src={props.image} alt={props.title} class="is-rounded" />
                </p>
            </div>
            <div className="column">
                <p className="title is-5">{props.title}</p>
                <p class="subtitle is-6">{props.native}</p>
                <p>{props.description}</p>
            </div>
        </div>




        {/* <hr /><div className="content">



                    <p class="title is-6">Aprūpe</p>
                    <p>{props.care}</p>

        </div> */}



    </article>
}

const Video = ({ url, captions }) => {
    return (<ReactPlayer
        url={url}
        width='100%'
        height='100%'
        // muted={true}
        config={{
            file: {
                attributes: {
                    controls: true,
                },
            },
            tracks: [
                { kind: 'captions', src: captions, label: "Instrukcijas", srcLang: 'lv', default: true }
            ]
        }}

    />)
}


// markup
const Hi = () => {

    const flowers = [



        {
            title: "Peonija",
            image: "https://i.imgur.com/BcCJXAQb.jpg",
            native: "Ziemeļamerika",
            description: "Tās ir neatņemama sastāvdaļa ikvienam vecmāmiņas dārzam, bet tagad tās ir palikušas par vienām no pieprasītākajām vasaras puķēm.",
        },
        {
            title: "Roze",
            image: "https://i.imgur.com/v35ns2pb.jpg",
            native: "Āzija",
            description: "Rozes ir iesakņojušās mūsu pasaulē ar lielo popularitāti un dažādajām nozīmēm. Vai zināji, ka pasaulē ir vairāk par 25 000 rožu šķirņu?",
        },
        {
            title: "Krūmroze",
            image: "https://i.imgur.com/GNaV8mUb.jpg",
            native: "Āzija",
            description: "Viens no daudzajiem rožu veidiem. Daudzi mazāki ziedi uz viena stublāja, lieliski piešķir krāsu ziedu kompozīcijām. Ar mazo izmēru tās lieliski papildina lielākus ziedus. To krūmi var sasniegt pat divu metru augstumu. Rozes ilgst līdz vienai nedēļai.",
        },
        {
            title: "Lefkoja jeb matiolas",
            image: "https://i.imgur.com/4l4p5ibb.jpg",
            native: "Vidusjūras apgabals",
            description: "Šoreiz Tev piegādājām salīdzinoši maz izziedējušas lefkojas, lai tās Tev vāzē stāvētu vēl ilgāk.",
        },
        {
            title: "Pistāciju lapas",
            image: "https://i.imgur.com/3oYQgBmb.jpg",
            native: "Centrālāzija un Tuvie Austrumi",
            description: "Pistāciju koku ģints ne tikai dod gardos riekstus, bet arī citus produktus, tostarp skaistus dekoratīvus zarus, kuru lapas lieliski papildina ziedu kompozīcijas.",
        },
        {
            title: "Citronkoka lapas",
            image: "https://i.imgur.com/F9Ix1eLb.jpg",
            native: "Dienvidāfrika",
            description: "Arī zināmas kā salal lapas, tās ir izturīgas un lieliski piešķir krāsu un apjomu ziedu kompozīcijām, tāpēc tās plaši izmanto floristi.",
        },

    ]
    return (
        <main class="">


            <Helmet title="Instrukcija un atsauksmes" defer={false} />

            <section class="section">

                <div class="columns is-mobile">
                    <div class="column pt-0">
                        <Logo />
                    </div>
                    <div class="column is-one-third pt-0">
                        <EndCheckout />
                    </div>
                </div>
                <hr />


                <div className="container content">


                    <h1 class="title is-3 has-text-centered">👋 Tavi ziedi ir klāt!</h1>


                    {/* <p className="title is-3">Ko darīt tālāk?</p> */}

                    <div class="columns  has-text-centered">
                        <div class="column">
                            <PlayOutline height="22px" width="22px" />
                            <p>Noskaties īso instrukciju video</p>
                        </div>
                        <div class="column">
                            <InformationCircleOutline height="22px" width="22px" />
                            <p>Iepazīsties ar padomiem, lai paildzinātu prieku par ziediem</p>
                        </div>
                        <div class="column">
                            <StarOutline height="22px" width="22px" />
                            <p>Novērtē saņemtos ziedus un padalies ar draugiem</p>
                            <p>
                                <a href="https://d6f6g7elvfj.typeform.com/to/q5g4j6lP" target="_blank" class="button is-outlined">Aizpildīt novērtējumu</a>

                            </p>
                        </div>
                    </div>

                </div>
            </section>

            <section className="section" style={{ backgroundColor: '#4a4a4a' }}>
                <div className="container content">


                    <Video
                        url="https://vz-da54803c-c33.b-cdn.net/bd0ba91b-f2f4-4d27-bc9e-48f4b016212c/playlist.m3u8"
                        captions="/titles/012.vtt"
                    />
                </div>
            </section>


            <section className="section">
                <div className="container content">

                    <div className="columns">
                        <div className="column">


                            <h2 class="title is-3">Peonijas ir klāt!</h2>

                            <p>Mūsu mīļākās ir laša krāsas peonijas, kas atveras lielumlielā ziedā, kā arī maina krāsu atveroties. Un šīs skaistās puķes paildina dažāda tipa un krāsas rozes, kā piemēram, pildītās angļu dārza rozes un dažādu krāsu krūmrozes un baltās, smaržīgās lefkojas. Kā rezultātā izveidojās mūsuprāt viena no skaistākajām kompozīcijām kāda mums ir izdevusies.</p>

                            <p>Pirms sāc:</p>

                            <ul>
                                <li>Lai kompozīcija izskatītos tik majestātiska, atrodi pareizo vāzi. Video redzamās vāzes augstums ir 20cm un kakliņš 14cm.</li>
                                <li>Ziedi transportēšanas dēļ var izskatīties noliekušies. Tas nekas - atjauno griezuma vietu un ieliec tos vāzē ar ūdeni. Pēc dažām stundām tie atgūs savu formu.</li>
                                <li>Ieber ziedu barību vāzē pirms kompozīcijas sākšanas, atceries 1 pulverītis uz 0,5l ūdens.</li>
                            </ul>
                                

                            <p>Ķeramies pie darba:</p>

                            <ul>
                                <li>Seko video, lai redzētu darba procesu. </li>
                            </ul>

                            <p>Kā patīk šīs nedēļas kompozīcija? Atsūti foto Instagram! Lūdzu sniedz anonīmu novērtējumu par saņemtajiem ziediem un piegādi - <a href="https://d6f6g7elvfj.typeform.com/to/q5g4j6lP" target="_blank" class="">Aizpildīt anketu</a></p>


                        </div>
                        <div className="column">

                            <img src="https://i.imgur.com/LvXf6Poh.jpg" alt="Nedēļas pušķis"/>

                            <h2 class="title is-34">Aprūpe</h2>

                            <p>Seko šīm norādēm, lai ziedi saglabātos ilgāk.</p>

                            <ol>
                                <li>Atceries ūdenī vāzē mainīt katru otro dienu</li>
                                <li>Izņem ziedus un noskalo ziedu kātus</li>
                                <li>Izlej ūdenu un izmazgā vāzi tīru</li>
                                <li>Iepildi istabas temperatūras ūdeni, tajā izšķīdinot ziedu barību</li>
                                <li>Ziediem nogriez 3cm no stublāju apakšas, ar asām šķērēm griežot slīpi, 45° lenķī</li>
                            </ol>

                            <h3 class="title is-4">Par ziedu barību</h3>

                            <p>Ziedu barībai ir svarīgi ievērot norādīto barības-ūdens attiecību, lai tā nav par stipru vai vāju. Ja tā būs par koncentrētu, tad ziedi var apdegt, ja koncentrācija par vāju, tad ziediem pietrūkt vajadzīgās barības vielas un tie ātrāk novecos. Mūsu līdzi dotā barības vielas paciņa, tiek šķīdināta 0,5l ūdens</p>

                        </div>
                    </div>




                </div>
            </section>




            <section className="section" style={{ backgroundColor: '#F0E2D7' }}>
                <div className="container content">

                    <p className="title is-3">Kādus ziedus saņēmi?</p>

                    <div className="columns is-multiline">
                        {flowers.map(f => {
                            return (
                                <div className="column is-one-third">
                                    <Stem
                                        title={f.title}
                                        latin={f.latin}
                                        image={f.image}
                                        native={f.native}
                                        care={f.care}
                                        description={f.description}
                                    />

                                </div>
                            )
                        })}


                    </div>


                </div>
            </section>


            <section className="section content">
                <div className="container">

                    <div className="columns">
                        <div className="column">

                            <h2 class="title is-3">Novērtējums</h2>

                            <p>Mēs priecāsimies, ja atstāsi godīgu novērtējumu par Heyday pakalpojumu.</p>
                            <p>Tas aizņems tikai 2 minūtes.</p>

                            <a href="https://d6f6g7elvfj.typeform.com/to/q5g4j6lP" target="_blank" class="button is-primary">Aizpildīt anketu</a>


                        </div>
                        <div className="column">

                            <h2 class="title is-3">Pasaki par mums saviem draugiem</h2>

                            <p>Ja Tev saņemtie ziedi patika, padalies ar Heyday saviem draugiem. Jo vairāk cilvēki pievienojas mūsu lokam, jo plašāk varēsim sniegt ziedu prieku.</p>

                            <p>
                                <a class="button is-success" href="whatsapp://send?text=Heyday ziedu abonements https://heyday.lv?utm_source%3Dshare%26utm_medium%3Dwhatsapp%26utm_campaign%3Dfromhi" data-action="share/whatsapp/share">Pārsūtīt saiti Whatsapp</a>
                            </p>


                        </div>
                    </div>





                </div>


            </section>

            <Footer />



        </main>
    )
}

export default Hi
